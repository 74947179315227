import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col, Card} from "react-bootstrap"
import DiveSiteMap from "../components/divesitemap"


class About extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About" />
        <Container>
          <Row>
            <Col sm={12}>
              <h1 className="page-title my-4">About the dive site at Capernwray</h1>
              <DiveSiteMap />
            </Col>
          </Row>
          <br />
          <Row>
          <Col sm={12}>
            <h2 className="mb-4">
              Whether you are a highly experienced diver or just starting out
              in this incredible sport, Capernwray offers the finest
              freshwater diving in the UK.
            </h2>
            <h3>Our underwater features:</h3>
            <ul>
              <li>
                Exceptional visibility and the second cleanest recreational
                bathing water in the UK (that’s official!).
              </li>
              <li>
                The finest stock of fish in any diving Centre, anywhere! Huge
                shoals of roach and perch, together with vast numbers of
                extremely friendly trout (!) and of course, our world-famous
                sturgeons, two of which are six feet long.
              </li>
              <li>
                Purpose-designed training platforms at depths from 2 metres to
                12 meters to satisfy every training requirement.
              </li>
              <li>
                Jetty with two piers for deep-water entry to simulate hard
                boat recovery.
              </li>
              <li>
                Concrete slipway for entry/exit to training area platforms.
              </li>
              <li>
                Two clear, buoyed and graduated training areas with two
                non-slip platforms positioned in 2 and 6 metres of water.
              </li>
              <li>
                Underwater cave to simulate lining-out techniques for wreck /
                cave diving.
              </li>
              <li>Numerous underwater attractions.</li>
            </ul>
            <hr />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card className='mb-5'>
                <Card.Header>
                  <h2>PODSNAP</h2>
                </Card.Header>
                <Card.Body>
                  <p>
                    A Dickens Class Harbour Minesweeper. Built in 1944
                    specifically to clear mines in around harbour entrances during
                    WWII. She is 50ft long, has a beam of 14ft and displaces 25
                    tons. She was scuttled at Capernwray in December 1995 and now
                    lies on her side in 18 metres.
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h2>WESSEX DRAGONFLY HELICOPTER</h2>
                </Card.Header>
                <Card.Body>
                  <p>
                    This old lady first flew in 1943 and was commissioned to HMS
                    Eagle in October 1953. She moved to the Advanced Training and
                    Development Unit in 1953 and continued in Service in a variety
                    of roles until her last flight in 1962. She then moved to the
                    Civil Aviation Fire School at Stanstead where she was used
                    extensively for crash rescue training techniques. Scrapped in
                    1991, she was bought by Capernwray Diving and Leisure Ltd in
                    1996 and now lies atop our ‘cave’ in 14 metres.
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h2>THE AFRICAN QUEEN</h2>
                </Card.Header>
                <Card.Body>
                  <p>
                    A ‘look-a-like’ for the famous vessel in the Humphrey Bogart
                    film of the same name! She sits on a slope in 12 metres of
                    water￼.
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h2>THE CAVE</h2>
                </Card.Header>
                <Card.Body>
                  <p>
                  Our ‘cave’ is in fact an old container which has been
                  ‘engineered’ to allow safe access inside. This provides a very
                  realistic ‘overhead environment’ suitable for lining -out etc.
                  whilst providing safe access and exit from each side. The top
                  of the ‘cave’ makes an ideal training platform in deeper water
                  (14m) than our novice training area. The Wessex helicopter
                  sits atop the cave.
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h2>DREAMER</h2>
                </Card.Header>
                <Card.Body>
                  <p>
                  One of the original wrecks at Capernwray and ideally located
                  at the end of the training area. Lies in 8 metres of water.
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h2>ORCA</h2>
                </Card.Header>
                <Card.Body>
                  <p>
                  Our most recent wreck and an excellent dive in a quieter part
                  of the quarry. A longish swim but well worth the effort. Look
                  out for the large shoals of roach on your way to this wreck.
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h2>NOVICE TRAINING AREA</h2>
                </Card.Header>
                <Card.Body>
                  <p>
                  A purpose-built area in safe, shallow water, specifically
                  designed for novice training. There are two platforms, one at
                  2m and one at 6m, which are suspended on lazy shots.
                  Importantly, the area is on a ledge on one side of the quarry
                  so trainees can be monitored and are able to carry out drills
                  in safety without the dangers of ‘drifting-off’ into deeper
                  water. The whole area is buoyed off and is easily accessed
                  from the slipway.
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h2>THE SUMP!</h2>
                </Card.Header>
                <Card.Body>
                  <p>
                    Not for novices but the deepest part of the quarry for those
                    who need to log a 20m dive. This was where the original quarry
                    was drained (hence its depth in relation to the remainder of
                    the quarry which is normally 18m). CAUTION: The very nature of
                    this depression in the floor of the quarry allows silt to
                    build up so watch your buoyancy control to avoid ‘stirring-up’
                    the bottom. Marked by a shotline and buoyed by a large plastic
                    goose!
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h2>THE GYPSY MOTH</h2>
                </Card.Header>
                <Card.Body>
                  <p>
                  A very nice wreck in 17m. A spitting image of the yacht of the
                  same name made famous by Sir Francis Chichester’s solo
                  non-stop round the world voyage.
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h2>CANDIDA II</h2>
                </Card.Header>
                <Card.Body>
                  <p>
                  An unusual name for a vessel of the High Seas (!) but an
                  excellent dive nevertheless. She sits alone on the far side of
                  the quarry and is rarely visited, so you are guaranteed a
                  quiet dive and generally good viz! A long surface swim needed
                  to reach her but well worth the effort. She lies on the bottom
                  in 18 metres.
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h2>THE CANNON</h2>
                </Card.Header>
                <Card.Body>
                  <p>
                  Originally destined for the front of the shop as an
                  interesting feature, we decided that this old weapon of an
                  earlier sea-faring age would be much more interesting as an
                  underwater exhibit. Not much to see but a good check of your
                  whereabouts underwater when you bump into it! Lies in 18m and
                  marked by a red buoy.
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                <h2>THE GNOME GARDEN</h2>
                </Card.Header>
                <Card.Body>
                  <p>
                  A very special feature to Capernwray! Not to be confused with
                  any other ‘gardens’ of similar nature in the North Lands but
                  our very own collection of garden gentleman kindly donated by
                  one of our regular visiting dive schools. The location is
                  secret; see if you can find them yourselves! (Please do not
                  touch; leave the gnomes alone for others to enjoy).
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h2>LORD LUCAN AND SHERGAR</h2>
                </Card.Header>
                <Card.Body>
                  <p>
                  Possibly our most famous attractions, the two fibreglass
                  horses from Blackpool Pleasure Beach are a significant
                  addition to our novice training area and provide a unique
                  underwater feature. Come and ride Shergar and see for
                  yourself!
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                <h2>NEW WESSEX HELICOPTER</h2>
                </Card.Header>
                <Card.Body>
                  <p>
                  A magnificent new addition to our in-water attractions, this
                  is a genuine Wessex helicopter (much larger than our ‘old’
                  helicopter on top of the ‘cave’), resplendent in bright
                  day-glo yellow, sitting atop a container to keep her clear of
                  the bottom in about 15 metres. She makes a wonderful dive and
                  her arrival here yet again puts us out in front in terms of
                  in-water attractions for discerning divers!
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
