import React, { useState } from "react"
import Img from "gatsby-image"
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import { StaticImage } from "gatsby-plugin-image"
import diveSiteMapJPG from "../../content/assets/capernwray-map.jpg"
const diveSiteMapURL = "../../content/assets/capernwray-map.jpg"

const DiveSiteMap = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    
    return (
        <div className="m-0 p-0">
            <button className="p-0 m-0" type="button" onClick={() => {setIsOpen(true)}}>
                <StaticImage src={diveSiteMapURL} className="w-100 h-100" alt="The Dive Site" />
            </button>
            {isOpen && (
                <Lightbox
                  clickOutsideToClose={true}
                  mainSrc={diveSiteMapJPG}
                  onCloseRequest={() => setIsOpen(false)}
                />
            )}
        </div>
    )
}

export default DiveSiteMap